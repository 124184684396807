 import React, { useState, useEffect, useRef  } from 'react';
 import './App.css';
 import ReactPixel from 'react-facebook-pixel';
 import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

function App() {
  const [lan, setLan] = useState('ru');
  const [formStatus, setformStatus] = useState('closed');
 

  const lanObject = {
    ru:{
       callback: 'Обратный звонок',
       whatsupnum: '79312705454',
       tosite: 'Перейти на сайт',
       site: 'https://studia-54.com',
       needDesign: 'НУЖЕН ДИЗАЙН-ПРОЕКТ?',
       formHeadline: 'Оставьте заявку и мы перезвоним вам для обсуждения проекта',
       yourName: 'Ваше имя',
       yourPhone: 'Ваш телефон',
       send: 'Отправить',
       notice: 'Нажимая на кнопку “отправить”, я соглашаюсь на обработку персональных данных',
       postedtitle: 'Заявка отправлена',
       thanks: <><div>Скоро мы свяжемся с Вами.</div> <div>Хорошего дня!</div></>
    },
    en:{
       callback: 'Сallback',
       //whatsupnum: '97142154854',
       whatsupnum: '97142154854',
       tosite: 'Go to the website',
       site: 'https://studia-54.com/en/',
       needDesign: 'Do you need the design-project?',
       formHeadline: 'Make a request and we recall you for discussing project.',
       yourName: 'Name',
       yourPhone: 'Phone',
       send: 'Send',
       notice: 'By clicking on the “Send” button, I agree to the processing of personal data',
       postedtitle: 'The application has been sent',
       thanks: <><div>We will contact you soon.</div> <div>Have a nice day!</div></>
    }
  }

    const name = useRef();
    const phone = useRef();



  let submit = function(e){
    e.preventDefault();
    setformStatus('posted');

    let newPost = new FormData(); // create single instance
    newPost.append('name', name.current.value); // this cmd will not change
    newPost.append('phone', phone.current.value);
 
    //ReactPixel('track', 'Contact');
    
    
     //ym('reachGoal', 'whateverGoal', {awesomeParameter: 42});

fetch('/php/contact2.php', {
  method: 'POST', // Здесь так же могут быть GET, PUT, DELETE
  body: newPost, // Тело запроса в JSON-формате
})
  .then((response) => response.json())
  .then((data) => {
    
    window.ym(95766536,'reachGoal','FORM');
    ReactPixel('track', 'Contact');
    console.log(data);
    // {title: "foo", body: "bar", userId: 1, id: 101}
  });
    //запрос в php/contact.php
  }

  const [value, setValue] = useState();
            


  let form = '';
  if(formStatus == 'active'){
    form = <div className="callback-form">
          <div onClick={()=>{setformStatus('closed')}} className="callback-form__formclose">
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
             <line x1="14.8986" y1="29.3144" x2="29.0408" y2="15.1723" stroke="white" stroke-width="1.5"/>
             <line x1="29.0407" y1="29.459" x2="14.8986" y2="15.3169" stroke="white" stroke-width="1.5"/>
          </svg>
          </div>
     
         <form onSubmit={submit} className="callback-form__form">
          <div className="callback-form__title">{lanObject[lan].needDesign}</div>
         <div className="callback-form__headline">{lanObject[lan].formHeadline}</div>
         <input ref={name} placeholder={lanObject[lan].yourName} type="text"/>
         {/*<input ref={phone}  placeholder={lanObject[lan].yourPhone} type="tel"/>*/}
           <PhoneInput
           ref={phone} 
            international
            defaultCountry="RU"
      placeholder={lanObject[lan].yourPhone}
      value={value}
      onChange={setValue}/>
         <button className="callback-form__formbutton">{lanObject[lan].send}</button>
          <div className="callback-form__notice">{lanObject[lan].notice}</div>
         </form>
        

       </div>;
  }
  else if (formStatus == 'posted'){
    form =  <div className="callback-form">
          <div onClick={()=>{setformStatus('closed')}} className="callback-form__formclose">
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
             <line x1="14.8986" y1="29.3144" x2="29.0408" y2="15.1723" stroke="white" stroke-width="1.5"/>
             <line x1="29.0407" y1="29.459" x2="14.8986" y2="15.3169" stroke="white" stroke-width="1.5"/>
          </svg>
          </div>
     
         <form onSubmit={submit} className="callback-form__form">
           <div className="callback-form__title">{lanObject[lan].postedtitle}</div>
           <div className="callback-form__headline">{lanObject[lan].thanks}</div>
         </form>
        

       </div>;
  } 


  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  const backArrayDesktop = ['/back.jpg'];
  const backArrayMobile = ['/mback1.jpeg', '/mback2.jpeg', '/mback3.jpeg','/mback4.jpeg'];

  const isMobile = window.innerWidth <= 768; // Adjust the threshold based on your design

  const backArray = isMobile ? backArrayMobile : backArrayDesktop;



  useEffect(() => {
    const backgroundInterval = setInterval(() => {
      setOverlayOpacity(1); // Set overlay to fully opaque
      setTimeout(() => {
        setOverlayOpacity(0); // Set overlay to fully transparent after 0.5s
        setBackgroundIndex((prevIndex) => (prevIndex + 1) % backArray.length);
      }, 500);
    }, 3000);

    return () => {
      clearInterval(backgroundInterval);
    };
  }, []);



  useEffect(() => {
    if(window.location.pathname == '/en/' ||  window.location.pathname == '/en'){
      setLan('en');
    }
    console.log(window);
    console.log(window.location.pathname);
     

    ReactPixel.init('882721536597640');
    ReactPixel.pageView();
    
  }, []);
 


  const metrica = 
  '!function(e,t,c,n,r,a,m){e.ym=e.ym||function(){(e.ym.a=e.ym.a||[]).push(arguments)},e.ym.l=1*new Date;for(var s=0;s<document.scripts.length;s++)if(document.scripts[s].src===n)return;a=t.createElement(c),m=t.getElementsByTagName(c)[0],a.async=1,a.src=n,m.parentNode.insertBefore(a,m)}(window,document,"script","https://mc.yandex.ru/metrika/tag.js"),ym(95766536,"init",{clickmap:!0,trackLinks:!0,accurateTrackBounce:!0,webvisor:!0});';

  return (
    <div className="App" style={{
      'height':'100vh', 
      'backgroundSize':'cover', 
      'backgroundPosition': 'center', 
      'backgroundImage': `url(${backArray[backgroundIndex]})`,
      'transition': 'background-image 1s ease-in-out',
      }}>

             {/* Overlay with variable opacity */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0)',
          opacity: overlayOpacity,
          transition: 'opacity 0.5s ease-in-out',
          pointerEvents: 'none', // Make sure the overlay doesn't block interactions
        }}
      ></div>

        
       <div className="page-wrap" style={{zIndex: '100', position: 'relative'}}>

       <div className="app-phones">
                {lan == 'ru' ? (
          <>
           <a className="App-phone" href="tel:+79312705454">+7 (931) 270-54-54 </a>
          {/*<a className="App-phone" href="tel:+97142154854">+9 (714) 215-48-54</a>*/}
        </>
      ) : (
        <>
          {/*<a className="App-phone" href="tel:+97142154854">+9 (714) 215-48-54 (World)</a>*/}
          <a className="App-phone" href="tel:+97142154854">+971 4 215 48 54</a>
        </>
      )}

       
        </div>
   

        <img   className="app-logo" src="/logo.svg"/>


 
       <div className="app-buttons">
          <div onClick={()=>{setformStatus('active')}} className="app-button">{lanObject[lan].callback}</div>
          <a target='_blank' onClick={()=>{window.ym(95766536,'reachGoal','WAPP')}} className="app-button" href={"https://wa.me/"+lanObject[lan].whatsupnum}>Whatsapp</a>
          <a target='_blank'  onClick={()=>{window.ym(95766536,'reachGoal','SITE')}}  className="app-button" href={lanObject[lan].site}>{lanObject[lan].tosite}</a> 
       </div>



       <div className="app-langs">
             {lan == 'ru' ? (
          <>
           <span className="active">Ру</span>
           <span onClick={()=>{setLan('en')}}>En</span>
        </>
      ) : (
        <>
           <span onClick={()=>{setLan('ru')}}>Ру</span>
           <span className="active">En</span>
        </>
      )}


       </div>
   
   <div style={{display: 'none'}}> 
       <img src="/mback1.jpeg"/>
       <img src="/mback2.jpeg"/>
       <img src="/mback3.jpeg"/>
       <img src="/mback4.jpeg"/>
        </div>

       </div>


       {form}

    {/*   <script
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: metrica }}
      />*/}
     
       {/* <YMInitializer accounts={[95766536]} options={{webvisor: true}} version="2"/> */}
    </div>
  );
}

export default App;
